import {defineStore} from "pinia";

export const useSubscribeInfoStore = defineStore("subscribeInfo", {
  state: () => ({
    currentSubscribeStep: 1,
    shippingAddress: {
      firstName: "",
      lastName: "",
      streetAddress: "",
      details: "",
      city: "",
      state: "",
      zip: "",
      mobile: "",
    },
    paymentInfo: {
      cardId: "",
      paymentMethodId: "",
      creditCardNumber: "",
      cardBrand: "",
      billingAddress: {
        firstName: "",
        lastName: "",
        streetAddress: "",
        details: "",
        city: "",
        state: "",
        zip: "",
      },
      isSameAddress: true,
    },
    invitationCode: ""
  }),
  getters: {
    getterCurrentSubscribeStep(state) {
      return state.currentSubscribeStep
    },
    getterShippingAddress(state) {
      return state.shippingAddress
    },
    getterPaymentInfo(state) {
      return state.paymentInfo
    },
    getterInvitationCode(state) {
      return state.invitationCode
    }
  },
  actions: {
    updateCurrentSubscribeStep(currentSubscribeStep) {
      this.currentSubscribeStep = currentSubscribeStep
    },
    updateShippingAddress(shippingAddress) {
      this.shippingAddress = shippingAddress
    },
    updatePaymentInfo(paymentInfo) {
      this.paymentInfo = {...this.paymentInfo, ...paymentInfo}
    },
    updateInvitationCode(invitationCode) {
      this.invitationCode = invitationCode
    },
  }
})
